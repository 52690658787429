import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../Icon";
import FeatureCard from "../FeatureCard";

interface MaximizeRelevanceProps {
  title?: string;
  description?: string;
  features?: any;
}
const MaximizeRelevanceSection: React.FC<MaximizeRelevanceProps> = props => {
  const { title, description, features } = props;

  const graphic = useStaticQuery(graphql`
    query {
      tree: file(relativePath: { eq: "treeright.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="relative">
      <Section>
        <div className="w-full md:w-3/4">
          <Header
            variant={HeaderVariant.h2}
            className="text-center md:text-left"
          >
            {title}
          </Header>
          <div className="text-gray-3-dark text-xl mb-16 md:text-2xl md:font-semibold text-center md:text-left">
            {description}
          </div>
        </div>
        <img
          className="absolute right-0 top-18 -translate-y-1/2 w-60 hidden md:block"
          src={graphic?.tree?.publicURL}
          aria-hidden
        />
        <div className="grid grid-cols-1 justify-center items-center sm:grid-cols-2 md:grid-cols-4">
          {features?.map((item, index) => {
            return (
              <div className="">
                <FeatureCard
                  key={index}
                  svg={item?.svg?.url}
                  feature={item?.text}
                />
              </div>
            );
          })}
        </div>
      </Section>
    </div>
  );
};

export default MaximizeRelevanceSection;
