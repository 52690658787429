import classNames from "classnames";
import React from "react";
import Icon, { IconType } from "../../Icon";
import Bullet, { BulletSize, BulletVariant } from "../../Bullet";

interface FeatureCardProps {
  icon?: IconType;
  feature: string;
  svg?: any;
}

const FeatureCard: React.FC<FeatureCardProps> = props => {
  const { icon, feature, svg } = props;

  return (
    <div className="flex flex-col">
      <div className="h-14 mb-6 flex justify-center sm:justify-start">
        {svg ? (
          <span className="rounded-full inline-block bg-blue-3-light p-4">
            <img width="22" height="24" src={svg} alt="" />
          </span>
        ) : (
          ""
        )}
      </div>
      <div
        className={classNames(
          "text-xl text-gray-1 mx-auto sm:mx-0 mb-12 sm:mb-0 text-center sm:text-left max-w-60 pr-4"
        )}
      >
        {feature}
      </div>
    </div>
  );
};

export default FeatureCard;
