import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

interface StatisticProps {
  title: string;
}

const Statistics: React.FC<StatisticProps> = props => {
  const { title } = props;
  const bgImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dsp/dsp-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <div className="relative my-22 overflow-hidden">
      <div className="w-full">
        <GatsbyImage
          image={bgImage?.file?.childImageSharp?.gatsbyImageData}
          alt=""
        />
      </div>
      <p className="w-full max-w-8xl px-6 sm:px-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mx-auto text-white text-2xl sm:text-4xl md:text-5xl font-bold text-left z-10">
        {props?.title}
      </p>
    </div>
  );
};

export default Statistics;
