import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CTA } from "../../../constants/text";
import { URL, Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import { useSegment, trackView } from "../../../utils/analytics";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import cn from "classnames";
import {
  Name,
  Type,
  Location,
  Category
} from "../../../utils/analytics/constants";
import { GatsbyImage } from "gatsby-plugin-image";
import Icon, { IconType } from "../../Icon";
import Paragraph from "../../Paragraph";
import { nav } from "../../../utils/navigation";

interface AdvertisingSoftwareProps {
  title?: string;
  description?: string;
  features?: any;
  alwaysOnCampaignImage?: any;
  asinLevelImage?: any;
  cta?: any;
}

const AdvertisingSoftwareSection: React.FC<
  AdvertisingSoftwareProps
> = props => {
  const {
    title,
    description,
    features,
    alwaysOnCampaignImage,
    asinLevelImage,
    cta
  } = props;
  const { segment } = useSegment();
  const [activeSelection, setActiveSelection] = React.useState<number>(0);

  const mocks = useStaticQuery(graphql`
    query {
      optimization: file(relativePath: { eq: "dsp/mocks/optimization.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      execution: file(relativePath: { eq: "dsp/mocks/execution.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const selector = [
    {
      title: "Always-on campaign optimization",
      image: mocks.optimization.childImageSharp.gatsbyImageData,
      icon: IconType.optimize,
      description:
        "Enhance your full-funnel advertising strategy with always-on optimizations. Perpetua’s ad engine automatically shifts budgets between line items, and even outperforms Amazon’s native console by 20%."
    },
    {
      title: "ASIN-level reporting across DSP and sponsored ads",
      image: mocks.execution.childImageSharp.gatsbyImageData,
      icon: IconType.sales,
      description:
        "Monitoring performance across all ad types has never been easier. Perpetua’s consolidated dashboards integrate Amazon DSP and Sponsored Ads data, giving advertisers full visibility of ad performance for every ASIN."
    }
  ];

  return (
    <div className="bg-blue-3-light rounded-tl-small rounded-br-small md:rounded-tl-large md:rounded-br-large pt-20 lg:py-36 overflow-hidden">
      <Section>
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col w-full lg:w-1/2">
            <Header
              variant={HeaderVariant.h2}
              className="text-center md:text-left"
            >
              {title}
            </Header>
            <div className="text-xl md:text-2xl text-gray-3-dark md:font-semibold text-center md:text-left">
              {description}
            </div>
            <div className="flex pt-8 pb-12 justify-start">
              {features &&
                features?.map((item: any, i: number) => {
                  return (
                    <button
                      onClick={() => setActiveSelection(i)}
                      className="flex items-center group max-w-xs mr-4"
                      id={item.key}
                    >
                      <div
                        className={cn(
                          "max-w-xs flex p-3 rounded-full bg-white items-center mr-3",
                          i === activeSelection ? "shadow-xl" : "opacity-20",
                          "group-hover:opacity-100 group-hover:shadow-xl"
                        )}
                      >
                        <img
                          width="24"
                          height="24"
                          src={item?.icon?.url}
                          alt=""
                        />
                      </div>
                      <span
                        className={cn(
                          "bg-gradient-to-r text-left font-medium",
                          i !== activeSelection
                            ? "text-gray-3-light"
                            : "text-transparent bg-gradient-to-r from-purple-1 to-blue-1 bg-clip-text",
                          "group-hover:text-gray-3-dark"
                        )}
                      >
                        {item?.title}
                      </span>
                    </button>
                  );
                })}
            </div>
            <Paragraph>
              {features?.[activeSelection]?.description?.description}
            </Paragraph>
            <Button
              id="features-advertising-software"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={cta?.text}
              className={"display-advertising-chatbot w-full md:w-max mt-18"}
              onClick={() => {
                trackView(Name.Cta, {
                  category: Category.ButtonClick,
                  type: Type.Button,
                  text: cta?.text,
                  page_url: window?.location.href,
                  destination: cta?.route,
                  location: Location.Feature
                });
                nav(cta.route);
              }}
            />
          </div>
          <div className=" w-full max-w-2xl mb-12 mx-auto lg:w-1/2 lg:mb-0">
            <GatsbyImage
              image={
                activeSelection == 0 ? alwaysOnCampaignImage : asinLevelImage
              }
              alt=""
              aria-hidden
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default AdvertisingSoftwareSection;
