import React from "react";
import { graphql } from "gatsby";
import BottomCTA from "../components/BottomCta";
import AdvertisingSoftwareSection from "../components/DisplayAdvertising/AdvertisingSoftware";
import HeroSection from "../components/HeroSection";
import ManagedServiceSection from "../components/DisplayAdvertising/ManagedService";
import MaximizeRelevanceSection from "../components/DisplayAdvertising/MaximizeRelevance";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import Statistics from "../components/DisplayAdvertising/Statistics";
import Story from "../components/DisplayAdvertising/Story";
import Bayer from "../components/Brands/BrandSvgs/bayer.svg";
import CalvinKlein from "../components/Brands/BrandSvgs/calvinklein.svg";
import Crocs from "../components/Brands/BrandSvgs/crocs.svg";
import DrTobias from "../components/Brands/BrandSvgs/drtobias.svg";
import FourSigmatic from "../components/Brands/BrandSvgs/foursigmatic.svg";
import HeroCosmetics from "../components/Brands/BrandSvgs/herocosmetics.svg";
import Lacoste from "../components/Brands/BrandSvgs/lacoste.svg";
import Umbra from "../components/Brands/BrandSvgs/umbra.svg";
import { IconType } from "../components/Icon";
interface DisplayAdvertisingPageProps {
  data: any;
}

const DisplayAdvertisingPage: React.FC<DisplayAdvertisingPageProps> = ({
  data
}) => {
  const { segment, helpers } = useSegment();

  const pageData = data?.allContentfulAmazonDemandSidePlatform?.nodes[0];

  const brandsList = [
    {
      src: <Crocs />,
      filter: true
    },
    {
      src: <CalvinKlein />,
      filter: false
    },
    {
      src: <HeroCosmetics />,
      filter: false
    },
    {
      src: <Lacoste />,
      filter: false
    },
    {
      src: <FourSigmatic />,
      filter: false
    },
    {
      src: <Bayer />,
      filter: false
    },
    {
      src: <DrTobias />,
      filter: false
    },
    {
      src: <Umbra />,
      filter: false
    }
  ];

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <div className="space-y-20 lg:space-y-36">
        <HeroSection
          title={pageData?.mainTitle}
          h1Title={pageData?.h1Title}
          description={pageData?.mainDescription?.mainDescription}
          image={pageData?.mainImage?.gatsbyImageData}
          cta={pageData?.heroCta}
          secondaryCta={pageData?.secondaryHeroCta}
          brandsList={brandsList}
          icon={IconType.dsp}
        />
        <ManagedServiceSection
          title={pageData?.managedServiceTitle}
          description={
            pageData?.managedServiceDescription?.managedServiceDescription
          }
          image={pageData?.managedServiceImage?.gatsbyImageData}
          features={pageData?.managedServiceFeatures}
          cta={pageData?.managedServiceCta}
        />
        <AdvertisingSoftwareSection
          title={pageData?.advertisingTitle}
          description={pageData?.softwareDescription?.softwareDescription}
          features={pageData?.softwareFeatureSelector}
          alwaysOnCampaignImage={pageData?.alwaysOnCampaign?.gatsbyImageData}
          asinLevelImage={pageData?.asinLevel?.gatsbyImageData}
          cta={pageData?.advertisingSoftwareCta}
        />
        <MaximizeRelevanceSection
          title={pageData?.maximizeRelevanceTitle}
          description={
            pageData?.maximizeRelevanceDescription?.maximizeRelevanceDescription
          }
          features={pageData?.maximizeRelevanceFeatures}
        />
        <Statistics title={pageData?.statisticsSectionTitle} />
        <Story
          image={pageData?.storySectionImage?.url}
          description={
            pageData?.storySectionDescription?.storySectionDescription
          }
          personName={pageData?.storyPersonName}
          personTitle={pageData?.storyPersonTitle}
          personImage={pageData?.storyPersonImage?.gatsbyImageData}
          cta={pageData?.storyCta}
        />
        <BottomCTA
          primaryButtonId={pageData?.bottomCta?.primaryButtonId}
          secondaryButtonId={pageData?.bottomCta?.secondaryButtonId}
          title={pageData?.bottomCta?.title}
          subtitle={pageData?.bottomCta?.subTitle}
          primaryButtonText={pageData?.bottomCta?.primaryButtonText}
          primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
          secondaryButtonClassName={
            pageData?.bottomCta?.secondaryButtonClassName
          }
          primaryButtonOnClick={() => {
            helpers
              .delayTrack(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: pageData?.bottomCta?.primaryButtonText,
                page_url: window?.location.href,
                destination: pageData?.bottomCta?.primaryButtonRoute,
                location: Location.BottomCta
              })
              .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
          }}
          secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
          secondaryButtonOnClick={() => {
            trackView(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.secondaryButtonText,
              page_url: window?.location.href,
              destination: pageData?.bottomCta?.secondaryButtonRoute,
              location: Location.BottomCta
            });
            nav(pageData?.bottomCta?.secondaryButtonRoute);
          }}
        />
      </div>
    </Layout>
  );
};

export default DisplayAdvertisingPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulAmazonDemandSidePlatform(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          robots
        }
        title
        h1Title
        mainTitle
        mainImage {
          gatsbyImageData
          url
        }
        mainDescription {
          mainDescription
        }
        inputPlaceholder
        heroCta {
          text
          route
        }
        secondaryHeroCta {
          route
          text
        }
        managedServiceTitle
        managedServiceDescription {
          managedServiceDescription
        }
        managedServiceImage {
          gatsbyImageData
          url
        }
        managedServiceFeatures {
          text
          svg {
            gatsbyImageData
            url
          }
        }
        managedServiceCta {
          text
          route
        }
        advertisingTitle
        softwareDescription {
          softwareDescription
        }
        softwareFeatureSelector {
          icon {
            gatsbyImageData
            url
          }
          title
          description {
            description
          }
        }
        alwaysOnCampaign {
          gatsbyImageData
          url
        }
        asinLevel {
          gatsbyImageData
          url
        }
        advertisingSoftwareCta {
          text
          route
        }
        maximizeRelevanceTitle
        maximizeRelevanceDescription {
          maximizeRelevanceDescription
        }
        maximizeRelevanceFeatures {
          svg {
            url
            gatsbyImageData
          }
          text
        }
        statisticsSectionTitle
        storySectionImage {
          gatsbyImageData
          url
        }
        storySectionDescription {
          storySectionDescription
        }
        storyPersonName
        storyPersonTitle
        storyPersonImage {
          gatsbyImageData
          url
        }
        storyCta {
          text
          route
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          primaryButtonText
          secondaryButtonId
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
