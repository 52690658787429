import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Paragraph from "../../Paragraph";
import Crocs from "../../../images/dsp/crocs-logo.svg";
import { nav } from "../../../utils/navigation";
import Button, { ButtonVariant, ButtonSize } from "../../Button";
import { IconType } from "../../Icon";
import { useI18next } from "gatsby-plugin-react-i18next";

interface StoryProps {
  image?: any;
  description?: string;
  personName?: string;
  personTitle?: string;
  personImage?: string;
  cta?: any;
}

const Story: React.FC<StoryProps> = props => {
  const { image, description, personName, personTitle, personImage, cta } =
    props;

  const { language } = useI18next();

  return (
    <div className="mx-5">
      <div className="flex flex-col max-w-6xl mx-auto lg:flex-row rounded-tl-small rounded-tr-small rounded-bl-small rounded-br-small rounded-3xl border border-gray-4-light">
        <div className="text-center flex justify-center p-5 lg:p-20 rounded-tl-small rounded-tr-small lg:rounded-bl-small  lg:rounded-r-none  lg:text-left bg-gradient-to-br to-blue-3-light from-white  ">
          <GatsbyImage image={personImage} alt="" />
        </div>
        <div className="mx-3 flex flex-col items-start rounded-lg p-6 lg:w-1/2">
          <Crocs />
          <Paragraph className="my-10">{description}</Paragraph>
          <p className="text-base font-medium">{personName}</p>
          <p className="text-base font-medium text-gray-2-light">
            {personTitle}
          </p>
          {language == "en-US" && cta?.text != null && (
            <Button
              id="read-the-story"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={cta?.text}
              icon={IconType.whiteBook}
              className="mt-10"
              onClick={() => {
                nav(cta?.route);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Story;
