import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { CTA } from "../../../constants/text";
import { URL, Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import { useSegment, trackView } from "../../../utils/analytics";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import Icon, { IconType } from "../../Icon";
import FeatureCard from "../FeatureCard";
import {
  Name,
  Type,
  Location,
  Category
} from "../../../utils/analytics/constants";
import { GatsbyImage } from "gatsby-plugin-image";
import { nav } from "../../../utils/navigation";

interface ManagedServiceProps {
  title?: string;
  description?: string;
  image?: any;
  features?: any;
  cta?: any;
}

const ManagedServiceSection: React.FC<ManagedServiceProps> = props => {
  const { title, description, image, features, cta } = props;
  const { segment } = useSegment();

  return (
    <Section>
      <div className="flex flex-col lg:flex-row w-full items-center">
        <div className="w-full lg:w-1/2 h-full max-w-156">
          <GatsbyImage
            image={image}
            alt=""
            className="place-self-center w-full h-full"
          />
        </div>
        <div className="flex flex-col lg:w-1/2">
          <Header
            variant={HeaderVariant.h2}
            className="text-center md:text-left"
          >
            {title}
          </Header>
          <div className="text-2xl text-gray-3-dark md:font-semibold text-center md:text-left">
            {description}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {features?.map((feature, index) => {
              return (
                <div key={index} className="my-8">
                  <FeatureCard
                    key={feature?.key}
                    svg={feature?.svg && feature?.svg?.url}
                    feature={feature?.text}
                  />
                </div>
              );
            })}
          </div>
          <Button
            id="features-managed-service"
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            text={cta?.text}
            className={"display-advertising-chatbot w-full md:w-max"}
            onClick={() => {
              trackView(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: cta.text,
                page_url: window?.location.href,
                destination: cta?.route,
                location: Location.ManagedService
              });
              nav(cta?.route);
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default ManagedServiceSection;
